.table {
    th {
        font-size   : 12px;
        font-weight : 500;
        color       : #aaaaaa;
    }

    td {
        font-size   : 12px;
        font-weight : 700;
    }

    tr.clickable {
        &:hover {
            color : $martin;
        }
    }

    &.noheader {
        & > tbody > tr:first-of-type > td {
            border-top : none;
        }
    }
}

.table-found {
    font-size : 14px;
    color     : $ocean;
    margin    : 14px 0px;
}

/**
 * DATATABLES
 */

.dataTables_wrapper {
    min-height : 600px;

    .bottom {
        width      : 100%;
        text-align : center;
    }

    .dataTables_length,
    .dataTables_filter {
        display : none;
    }

    .dataTables_info {
        float     : right;
        font-size : 14px;
        color     : $ocean;
        margin    : 14px 0px;
    }

    .dataTables_paginate {
        margin     : 0 auto;
        text-align : center;
        float      : none;
        font-size  : 13px;

        .paginate_button {
            color : #aaa !important;

            &:hover,
            &.current:hover {
                color : #000 !important;
            }

            &,
            &.current,
            &:hover,
            &.current:hover,
            &:focus,
            &.current:focus {
                background : transparent !important;
                border     : 0 !important;
                padding    : 20px !important;
                margin     : 0 !important;
            }

            &.disabled,
            &.disabled:hover,
            &.disabled:active {
                color : #aaa !important;
            }
        }

        .paginate_button.current,
        .paginate_button.current:hover {
            background : transparent;
            border     : 0;
            padding    : 0;
            margin     : 0;
        }
    }
}

.datatable-toggle-filter {
    @include noselect();
}
