h1, h2, h3, h4, h5, h6, p, a, div, span {
    margin      : 0;
    padding     : 0;
    font-family : 'Droid Sans', sans-serif;
    color       : $mineshaft;
}

h1 {
}

h2 {
}

h3 {
    font-size   : 24px;
    font-weight : 600;
}

h4 {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.8px;
}

h5 {
}

h6 {
    font-weight : 400;
    font-size   : 15px;
    line-height : 20px;
}

.uppercase {
    text-transform : uppercase;
}

.lowercase {
    text-transform : lowercase;
}

.text-center {
    text-align : center;
}

.guest .review
{
	h2 {
		font-size: 28px;
		color: #b9bfc5;
		font-style: italic;
		margin-bottom: 15px;
		font-weight: 600;
	}
	p {
		line-height: 24px;
	}
	li {
		color: #b9bfc5;
		font-size: 18px;
		font-style: italic;
		margin-bottom: 15px;
		margin-left: -20px;
		list-style-type: circle;
		font-weight: 600;
	}
}