html, body {
    height : 100%;
}

input:focus,
input:active,
select:focus,
select:active,
textarea:focus,
textarea:active,
button:focus,
button:active {
    outline : none;
}

.nostyle {
    text-decoration : none;
    color           : inherit;

    &:hover {
        text-decoration : none;
        color           : inherit;
    }
}
