@mixin button-thin() {
    padding-right : 18px;
    padding-left  : 18px;
}

@mixin button-small() {
    padding        : 7px 16px;
    font-size      : 11px;
    font-weight    : 700;
    border-radius  : 1px;
    letter-spacing : .4px;
}

.btn {
    border-radius : 2px;
    padding       : 8px 34px;
    font-size     : 14px;
    font-weight   : 700;

    &.bottom {
        margin-top: 15px;
    }

    .icon {
        font-size      : 100%;
        font-weight    : bold;
        display        : inline;
        vertical-align : middle;
        color          : #fff;

        &.left {
            padding-right : 3px;
        }

        &.right {
            padding-left : 3px;
        }
    }
}

.btn-fill {
    width  : 100%;
    height : 50px;
}

.a-btn-wrapper {
	display: table;
}

.a-btn {
	display: table-cell;
}

.btn-small {
    @include button-small();
}

.btn-thin {
    @include button-thin();
}

.btn-ocean {
    color            : #fff;
    background-color : $ocean;
    border-color     : $ocean;
    &:hover {
        color : #fff;
    }
}

.btn-border {
    color            : #fff;
    background-color : transparent;
    border           : 2px solid #fff;
    transition       : color .3s, background .3s;

    &:hover {
        background : #fff;
        color      : #666;
        transition : color .3s, background .3s;
    }
}

.btn-default {
    border : 2px solid #ddd;
    color  : #aaa;

    &:hover {
        background : #ddd;
        border     : 2px solid #ddd;
        color      : $mineshaft;
    }
}

.btn-danger {
    border : 2px solid #d9534f;
}

.btn-martin {
    border     : 2px solid $martin;
    background : $martin;
    color      : #fff;

    &:hover {
        color : #fff;
    }
}
