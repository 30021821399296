.navbar {
    position      : fixed;
    width         : 100%;
    height        : $dashboardNavHeight;
    min-height    : $dashboardNavHeight;
    top           : 0;
    left          : 0;
    margin        : 0;
    z-index       : 999;
    color         : #ffffff;
    border-radius : 0;

    .container-fluid {
        height : 100%;
    }

    .navbar-brand {
        height  : 100%;
        padding : 10px 15px;
        color   : #ffffff;
    }

    .navbar-toggle {
        padding    : 7px 10px;
        margin-top : 5px;
    }

    .navbar-collapse {
        background : #242424;
    }

    .nav, .navbar-nav {
        & > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
            color : #555555 !important;
        }

        & > li > a {
            position    : relative;
            display     : block;
            padding     : 10px 20px;
            border-left : 1px solid rgba(255, 255, 255, 0.1);

            &.last {
                border-right : 1px solid rgba(255, 255, 255, 0.1);
            }

            img {
                height         : 14px;
                vertical-align : middle;
                margin-right   : 5px;
                margin-bottom  : 3px;
            }
        }
    }

    &.navbar-default {
        background     : #242424;
        border-color   : transparent;
        font-size      : 12px;
        text-transform : uppercase;

        .navbar-nav > li > a {
            color : #dedede;

            &:hover {
                color : #ffffff;
            }
        }
    }
}

/**
 * DROP DOWNS
 */
.dropdown-menu > li > a {
    font-size : 12px;
}