.checkbox-inline, .radio-inline {
    width : 22%
}

.checkbox {
    width  : auto;
    height : auto;
    margin : 0;
}

.guest {
	.panel-heading
	{
		.checkbox
		{
			display: inline;
			label {
				margin: 0;
			}
		}
	}
	
	div.radio 
	{
		margin-top: 0px;
	}
	
	.radio input[type="radio"],
	.radio-inline input[type="radio"],
	.checkbox input[type="checkbox"],
	.checkbox-inline input[type="checkbox"] {
	  margin-top: 12px;
	  cursor: pointer;
	}

	.radio input[type="radio"][disabled],
	.radio-inline input[type="radio"][disabled],
	.checkbox input[type="checkbox"][disabled],
	.checkbox-inline input[type="checkbox"][disabled] {
	  cursor: not-allowed;
	}
}

.radio-inline + .radio-inline, .checkbox-inline + .checkbox-inline {
    margin-left : 0;
}

label {
    display       : inline-block;
    max-width     : 100%;
    margin-bottom : 8px;
    font-weight   : bold;
    font-size     : 12px;
    margin        : 6px 0;

    &.disabled {
        opacity : .6;
    }
}

.form-required {
    color : $error;
}

.form-horizontal .form-group {
    margin : 0;
}

.has-error .form-control {
    border : 2px solid $error !important;

    &:focus {
        box-shadow : none;
    }
}

.form-control {
    $border-color : #cccccc;

    display          : block;
    width            : 100%;
    height           : 34px;
    padding          : 6px 20px;
    height           : 34px;
    font-size        : 13px;
    line-height      : 1.42857;
    color            : #555555;
    background-color : #ffffff;
    border           : 1px solid $border-color;
    border-radius    : 4px;
    border-radius    : 2px;
    transition       : border-color ease-in-out 0.15s, color ease-in-out 0.15s;
    box-shadow       : none;

    &:focus {
        border-color : $ocean;
        color        : $ocean;
        box-shadow   : none;
    }

    &::-webkit-input-placeholder,
    &:-moz-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder {
        color : $border-color;
    }
}

hr {
    margin-top    : 24px;
    margin-bottom : 24px;
}

.input-group-addon {
    background     : #aaaaaa;
    color          : #ffffff;
    font-size      : 12px;
    font-family    : 'Roboto';
    letter-spacing : 1px;
}

.form-inline, .radio-inline, .checkbox, .form-group.padded {
    margin : 10px 0;
}

.search-box {
    .search-input {
        color          : #ffffff;
        border         : 2px solid #ffffff;
        transition     : color .3s, background .3s;
        padding        : 5px 10px;
        font-size      : 13px;
        letter-spacing : 0.5px;
        background     : transparent;
        width          : 300px;
        display        : inline-block;
    }

    .search-icon {
        display          : inline-block;
        width            : 30px;
        vertical-align   : top;
        height           : 32px;
        padding          : 0;
        background-color : darken($ocean, 20);
        margin           : 0;
        transition       : background 0.3s;

        img {
            margin     : 0 auto;
            width      : 50%;
            margin-top : 8px;
        }

        &:hover {
            background : darken($ocean, 25);
            cursor     : pointer;
            transition : background 0.3s;
        }
    }
}