$primecolor : #569835;
$stackem : 1050px;

$primaryfont : 'Ubuntu', sans-serif;
$secondaryfont : 'Open Sans', sans-serif;
$thirdfont : 'Maven Pro', sans-serif;

body#landing {
    padding-bottom : 0;
}

body,
button,
input,
select,
textarea {
    color       : #333333;
    font-family : $primaryfont;
    font-size   : 16px;
    line-height : 18px;
    margin      : 0;
}

button {
    color : #ffffff;
}

p {

}

ul {
    list-style : none;
}

.contain {
    margin : 0 auto;
    width  : 100%;
}

.hide {
    display    : none;
    visibility : hidden;
}

a.nostyle {
    text-decoration : none;
    color           : #333;
    &:hover {
        text-decoration : underline;
    }
}

body {
    #menu {
        background-color : $primecolor;
        @include breakpoint($jeet-max-width) {
            background-color : #fff;
        }
        #logo {
            padding : 10px;
            margin  : 0 auto;
            width   : 98%;
            @include breakpoint($jeet-max-width) {
                width : $jeet-max-width;
            }
            div {
                background : url(/images/logo-small.png) no-repeat;
                height     : 68px;
                width      : 296px;
                @include breakpoint($jeet-max-width) {
                    background : url(/images/logo.png) no-repeat;
                    height     : 68px;
                    width      : 293px;
                }
            }
        }
    }
    #splash {
        background      : url('/images/header-phone.png') no-repeat center center;
        @include breakpoint(768px 1409px) {
            background : url('/images/header-tablet.png') no-repeat center center;
        }
        @include breakpoint($jeet-max-width) {
            background : url('/images/header.png') no-repeat center center;
        }
        background-size : cover;
        .contain {
            #splash-content {
                padding-left  : 35px;
                float         : left;
                margin-top    : 140px;
                margin-bottom : 120px;
                h1 {
                    color          : #333333;
                    font-family    : $primaryfont;
                    font-size      : 64px;
                    font-weight    : 700;
                    line-height    : 68px;
                    margin         : 0;
                    text-transform : uppercase;
                    @include breakpoint(768px) {
                        font-size   : 72px;
                        line-height : 70px;
                    }
                }
                p {
                    color       : #333333;
                    font-family : $primaryfont;
                    font-size   : 22px;
                    font-weight : 400;
                    line-height : 24px;
                    text-shadow : 0px 4px 4px rgba(255, 255, 255, 1);
                    margin      : 14px 0;
                }
                #instructions {
                    font-family   : $primaryfont;
                    font-size     : 20px;
                    line-height   : 20px;
                    margin-top    : 50px;
                    margin-bottom : 40px;
                    #highlight {
                        background-color : $martin;
                        border-radius    : 5px;
                        color            : #fff;
                        font-weight      : 700;
                        padding          : 6px 12px;
                        text-shadow      : none;
                    }
                }
                #signup {
                    input {
                        border        : 1px solid #141414;
                        border-radius : 5px;
                    }
                    input[type="text"], input[type="email"], input[type="password"] {
                        color       : #333;
                        font-family : $primaryfont;
                        font-size   : 18px;
                        font-weight : 500;
                        line-height : 18px;
                        margin      : 0 0 5px 0;
                        padding     : 18px;
                        width       : 100%;
                        &#name {
                            margin-right : 0%;
                            @include breakpoint($jeet-max-width) {
                                margin-right : 4%;
                            }
                        }
                    }
                    input[type="submit"] {
                        background-color : $primecolor;
                        border           : 0;
                        color            : #fff;
                        cursor           : pointer;
                        font-size        : 22px;
                        font-weight      : 700;
                        line-height      : 22px;
                        padding          : 12px 18px;
                        text-transform   : uppercase;
                        float            : left;
                    }
                    .input-error {
                        color       : #ae1e1e;
                        font-weight : 500;
                        font-size   : 16px;
                    }
                    .input-field {
                        margin : 0 0 10px 0;
                    }
                    .has-error {
                        border-color : #ae1e1e;
                    }
                }
                .white-shadow {
                    text-shadow : 1px 1px 2px white, 0 0 1em white, 0 0 0.2em white;
                }
            }
            @include cf();
        }
    }

    #features {
        background-color : #f8f8f8;
        margin           : 0;
        text-align       : center;
        padding-top      : 70px;
        width            : 100%;
        @include breakpoint($stackem) {
            background-color : #fff;
            margin           : 0 auto;
        }
        @include breakpoint($jeet-max-width) {
            width : $jeet-max-width;
        }
        h2 {
            color       : #333333;
            font-family : $primaryfont;
            font-size   : 45px;
            font-weight : 700;
            line-height : 45px;
            margin      : 0 0 14px;
        }
        #subtitle {
            color       : #333333;
            font-family : $primaryfont;
            font-style  : italic;
            font-size   : 25px;
            font-weight : 400;
            line-height : 25px;
            margin      : 10px 0 20px;
        }
        .bar {
            background-color : $primecolor;
            height           : 6px;
            margin           : 0 auto;
            width            : 78px;
        }
        #three {
            padding : 90px 0;
            margin  : 0;
            .block {
                padding-bottom : 50px;
                @include stack;
                @include breakpoint($stackem) {
                    @include unstack;
                    @include col(1/3);
                    text-align : center;
                }
                .icon {
                    margin : 0 auto;
                }
                .title {
                    color          : #333333;
                    font-family    : $primaryfont;
                    font-size      : 30px;
                    font-weight    : 700;
                    line-height    : 32px;
                    padding        : 0 30px;
                    text-transform : capitalize;
                    @include breakpoint($stackem) {
                        padding : 0;
                    }
                }
                .explanation {
                    color       : #5b5b5b;
                    font-family : $secondaryfont;
                    font-size   : 20px;
                    font-weight : 400;
                    line-height : 22px;
                    padding     : 0 30px;
                    @include breakpoint($stackem) {
                        padding : 0;
                    }
                }
            }
            @include cf();
        }
    }

    #acknowledge {
        margin  : 0 auto;
        padding : 40px;
        width   : 100%;
        h1 {
            color          : #333333;
            font-family    : $primaryfont;
            font-size      : 72px;
            font-weight    : 700;
            line-height    : 76px;
            margin         : 0;
            text-transform : uppercase;
        }
    }

    #footer-menu {
        font-family      : $thirdfont;
        font-size        : 25px;
        line-height      : 25px;
        width            : 100%;
        background-color : #569835;
        color            : #fff;
        font-weight      : 700;
        padding          : 20px 0;
        ul {
            text-align : center;
            margin     : 0;
            li {
                display : list-item;
                @include breakpoint(768px) {
                    display : inline;
                }
                padding : 0 10px;
                a {
                    color          : #fff;
                    text-transform : uppercase;
                }
            }
        }
    }
    footer {
        background-image    : url('/images/footer.png');
        background-position : left;
        background-color    : #ececec;
        padding-top         : 5px;
        padding-bottom      : 15px;
        p {
            font-size      : 16px;
            line-height    : 1.4;
            font-family    : $thirdfont;
            font-weight    : 300;
            padding-top    : 40px;
            padding-bottom : 40px;
            color          : #464646;
        }
        a {
            color           : #0071a2;
            text-decoration : underline;
        }
        span {
            clear   : none;
            display : inline-block;
            float   : none;
            margin  : 30px 0 0 20px;
        }
        .wrap {
            max-width     : 1280px;
            margin        : 0px auto;
            position      : relative;
            padding-left  : 10px;
            padding-right : 10px;
        }
        .fa {
            display                 : inline-block;
            font                    : normal normal normal 14px/1 FontAwesome;
            font-size               : inherit;
            text-rendering          : auto;
            -webkit-font-smoothing  : antialiased;
            -moz-osx-font-smoothing : grayscale;
            transform               : translate(0, 0);
        }
    }
}
