/**
 * Vendor files.
 *
 * The vendor directory is for third-party CSS. This is for when using
 * prepackaged components developed by other people (or for your own
 * components that are maintained in another project).
 */
@import 'vendor/all';

/**
 * Module files.
 *
 * The modules directory is reserved for Sass code that doesn't
 * cause Sass to actually output CSS. Things like mixin declarations,
 * functions, and variables.
 */
@import 'modules/all';

/**
 * Partials files.
 *
 * The partials directory is where the meat of my CSS is constructed.
 * Break things down into fine categories (typography, buttons,
 * textboxes, selectboxes, etc…).
 */
@import 'partials/all';

/**
 * Pages files.
 *
 * Page specific styles.
 */
@import 'pages/all';
