@import 'colors';
@import 'dimensions';
@import 'fonts';
@import 'images';
@import 'position';

@mixin noselect() {
    -webkit-touch-callout : none;
    -webkit-user-select   : none;
    -khtml-user-select    : none;
    -moz-user-select      : none;
    -ms-user-select       : none;
    user-select           : none;
}
