.breadcrumb {
    background    : #ffffff;
    padding-left  : 0;
    padding-right : 0;
    margin        : 5px 0;

    & > li + li:before {
        content : ">";
    }

    a {
        color     : $mineshaft;
        font-size : 14px;

        &.current {
            color           : $martin;
            text-decoration : none;
            font-weight     : 700;
        }

        &:hover {
            color           : $martin;
            text-decoration : none;
        }
    }
}

ul.breadcrumb-dots {
    margin     : 0;
    padding    : 0;
    text-align : center;
    min-width  : 1030px;

    li {
        display    : inline-block;
        margin     : 0 20px;
        position   : relative;
        width      : 160px;
        text-align : center;

        .breadcrumb-dot {
            @include noselect();

            width         : 35px;
            height        : 35px;
            border        : 2px solid #bbbbbb;
            color         : #000000;
            border-radius : 50%;
            text-align    : center;
            line-height   : 32px;
            font-size     : 14px;
            margin        : 10px auto;
            transition    : border .3s, background .3s, color .3s;

            &.current,
            &:hover {
                border-color    : $martin;
                background      : $martin;
                color           : #ffffff;
                cursor          : pointer;
                text-decoration : none;
                transition      : border .3s, background .3s, color .3s;
            }

            &:not(.noline) {
                &::after {
                    content          : '';
                    display          : block;
                    height           : 2px;
                    width            : 161px;
                    background-color : #d8d8d8;
                    position         : absolute;
                    top              : 27px;
                    right            : -102px;
                }
            }
        }
    }
}

.dot-header-filter {
    width: 80%;
    text-align: center;
    margin: 26px auto 0 auto;

    ul li {
        display: inline-block;
        padding: 10px;

        &:hover,
        &.active {
            cursor: pointer;
            color: $martin;
            text-decoration: underline;
        }
    }
}