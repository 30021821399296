$generators : url('/images/login.png');
$dot-img: url('/images/footer-50.png');

@mixin full-background($image) {
    background              : $image no-repeat center center fixed;
    background-size         : cover;
    -webkit-background-size : cover;
    -moz-background-size    : cover;
    -o-background-size      : cover;
    background-size         : cover;
}