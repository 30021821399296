.page-header {
    border : 0;

    h1 {
        color          : $mineshaft;
        text-transform : uppercase;
        padding-bottom : 8px;
        font-weight    : 600;
        font-size      : 34px;
        letter-spacing : 1.5px;

        small {
            text-transform : none;
            margin-left    : 6px;
            font-weight    : 600;
            font-size      : 55%;
            letter-spacing : 1px;
            color          : $nobel;
            font-style     : italic;
        }
    }
}

.guest
{
	.page-header
	{
		h1 
		{
			display        : inline-block;
			padding-right  : 20%;
			border-bottom  : 2px solid #2994c1;
		}
	}
	
	.review 
	{
		.page-header 
		{
			margin: 5px 0 20px;
			.row 
			{
				background-color: #dff0d8;
				margin: 0;
			}
			h1 
			{
				background-color: #dff0d8;
				padding: 15px 15px;
				border: none;
				text-transform: none;
				display: block;
				line-height: 34px;
			}
			.request_id 
			{
				background-color: #dff0d8;
				font-size: 16px;
				display: block;
				padding: 15px 15px;
				line-height: 34px;
				text-align: right;
			}
		}
		.sub-page-header 
		{
			margin-top: 50px;
			h1 
			{
				display        : block;
				padding-right  : 0;
				border-bottom  : 2px solid #2994c1;
			}
		}
	}
	
	.thanks-header
	{
		background: $ocean;
		padding: 30px;
		h1 
		{
			color: white;
			padding: 10px 0px;
		}
		p
		{
			color: white;
		}
		.checkmark {
			float: left;
			margin-right: 15px;
		}
		.message {
			float: left;
		}
	}
	
	.page-footer
	{
		padding: 30px;
		margin-top: 30px;
		background-color: black;
		p 
		{
			color: white;
			font-style: italic;
		}
	}
}

.dot-header {
    margin : 0;

    .dot-header-content {
        padding    : 40px 0;
        background : $dot-img;

        h1 {
            text-transform : uppercase;
            padding-bottom : 8px;
            color          : $mineshaft;
            font-weight    : 700;
            font-size      : 34px;
            letter-spacing : 0.75px;

            small {
                text-transform : none;
                margin-left    : 6px;
                font-weight    : 600;
                font-size      : 55%;
                letter-spacing : 1px;
                color          : $nobel;
                font-style     : italic;
            }
        }
    }

    .dot-header-footer {
        background : desaturate($ocean, 10);
        color      : #ffffff;
        font-size  : 14px;
        text-align : center;
        padding    : 6px 0;

        > span,
        .left > span,
        .right > span {
            color         : #ffffff;
            padding-right : 20px;
        }

        &.thin {
            padding : 0;
            height  : 2px;
            opacity : .3;
        }
    }
}

.dot-header-filter {
    @include noselect();
}