#login-container {
    position   : absolute;
    height     : 100%;
    z-index    : 10;
    top        : 0;
    left       : 0;
    padding    : 0 10%;
    background : rgba($martin, 0.80);
    overflow-y : auto;

    @media only screen and (max-width : 991px) {
        .form-group [class^='col-md-'] {
            margin-bottom : 15px;
        }
    }

    @media only screen and (max-width : 800px) {
        & {
            width : 100%;
        }
    }

    @media only screen and (max-width : 560px) {
        & {
            padding : 80px 0 0 0;
        }
    }

    #login-container-content {
        @include horizontal-align();
        @include vertical-align();
        width       : 350px;
        margin-left : 0;

        @media only screen and (max-width : 800px) {
            & {
                margin-left : auto;
            }
        }

        @media only screen and (max-width : 560px) {
            width                         : 100%;
            text-align                    : center;
            padding                       : 0px 25px;
        }

        #login-title-big {
            display     : block;
            font-size   : 168px;
            font-weight : 600;
            color       : #ffffff;
            line-height : 120px;
            margin-left : -8px;
            margin-top  : 10px;
        }

        #login-title-small {
            font-size      : 50px;
            text-transform : uppercase;
            color          : #ffffff;
            line-height    : 60px;
        }

        #login-tagline {
            margin : 40px 0;
        }

        .left-border-container {
            border-left  : 6px solid $ocean;
            padding-left : 12px;
            margin       : 30px 0;

            @media only screen and (max-width : 560px) {
                border-left: 0;
            }
        }

        form {
            input {
                background    : transparent;
                border        : 2px solid #ffffff;
                border-radius : 3px;
                height        : 50px;
                font-size     : 12px;
                color         : #ffffff;
                margin        : 10px 0;

                &::-webkit-input-placeholder {
                    color : #eeeeee;
                }

                &:-moz-placeholder {
                    color : #eeeeee;
                }

                &::-moz-placeholder {
                    color : #eeeeee;
                }

                &:-ms-input-placeholder {
                    color : #eeeeee;
                }
            }
        }
    }
}

#login-splash {
    @include full-background($generators);
    position : absolute;
    height   : 100%;
    width    : 100%;
    z-index  : 5;
    top      : 0;
    left     : 0;
}