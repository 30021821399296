/**
 * GRID
 */
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left  : 0;
    padding-right : 0;

    &.padded-left {
        padding-left : 5%;

        @media only screen and (max-width : 991px) {
            padding-left                  : 0px;
        }
    }

    &.padded-right {
        padding-right : 5%;

        @media only screen and (max-width : 991px) {
            padding-right                 : 0px;
        }
    }

    &.padded {
        padding-left  : 10px;
        padding-right : 10px;
    }
}

.gutter {
    padding : 0 16.66667%;
}

.row {
    margin : 20px 0;
}

.container-fluid {
    padding-left  : 10%;
    padding-right : 10%;

    &.nopadded {
        padding-left  : 0%;
        padding-right : 0%;
    }
}

.guest
{
	.dot-header-content
	{
		.container-fluid
		{
			padding-left: 0%;
			padding-right: 0%;
		}
	}
}	

/**
 * GENERAL
 */
.clickable {
    cursor : pointer;
}

/**
 * PANELS
 */
.panel.panel-default {
    border  : 0;
    padding : 0;
    margin  : 0;

    .panel-heading,
    .panel-body {
        margin  : 0;
    }

    .panel-heading {
        background: transparent;
        border-bottom  : 2px solid $ocean;
        font-size      : 17px;
        text-transform : uppercase;
        font-weight    : 700;
        letter-spacing : 1.2px;
        padding        : 5px 0;

        span {
            font-size : 15px;
        }
    }

    .panel-body {
        padding: 20px 10px;
    }
}
.guest .panel.panel-default
{
	border: 1px solid #ddd;
	
	.panel-heading
	{
		border-bottom: 1px solid transparent;
		color: white;
		text-transform: none;
		background: $ocean;
		padding: 10px;
	}
	
	.panel-toggle
	{
		float: right;
		color: white;
	}
}

.guest .review
{
	&>.row
	{
		border-left: 4px solid green;
		padding: 0 20px;
	}
}