.left {
  float: left;
}

.right {
  float: right;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}