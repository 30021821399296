/**
 * Horizontally align an element inside of a container.
 */
@mixin horizontal-align() {
    margin: 0 auto;
}

/**
 * Vertically align an element inside of a container.
 */
@mixin vertical-align($position: relative) {
    position: $position;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}