.alert {
    border-radius : 2px;
    border-color  : transparent;
    font-size     : 14px;

    ul {
        padding-left    : 16px;
        list-style-type : circle;
    }

    &.alert-danger {
        background-color : $error;
        color            : #ffffff;
    }

    &.alert-dismissable .close,
    &.alert-dismissible .close {
        color       : #ffffff;
        opacity     : 1;
        font-weight : 100;

        span {
            color : #ffffff;
        }
    }
}